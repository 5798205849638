// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getCouponBundlesCollections, postCouponBundlesFormData } from '@/services/coupon.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getCouponBundlesCollections().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[13].createDateFirstItem[0].options = res.data.collections.coupons
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        coupon_bundle: obj
      }
      Object.keys(params.coupon_bundle).forEach(res => {
        if (typeof params.coupon_bundle[res] === 'boolean') {
          if (params.coupon_bundle[res] === true) params.coupon_bundle[res] = '1'
          if (params.coupon_bundle[res] === false) params.coupon_bundle[res] = '0'
        }
      })
      postCouponBundlesFormData(params).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'voucherPackList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
